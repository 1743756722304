import React from "react";
import Select, {
  ActionMeta,
  MultiValue,
  Options,
  StylesConfig,
} from "react-select";

type Props = {
  value: string[];
  onChange: (
    newValue: MultiValue<string>,
    actionMeta: ActionMeta<string>,
  ) => void;
  options: Options<any>;
  label: string;
};
const MultipleSelect: React.FC<Props> = ({
  value,
  onChange,
  options,
  label,
}) => {
  return (
    <div className="min-w-[300px] w-[300px] mb-4">
      <h5 className="text-gray-700 mb-2">{label}</h5>
      <Select
        value={value}
        onChange={onChange}
        isMulti
        name={label}
        options={options}
      />
    </div>
  );
};

export default MultipleSelect;
