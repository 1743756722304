import React, { memo, useState } from "react";
import { ClickAwayListener } from "@mui/material";
import MainCard from "./MainCard";
import { Search, Check, Remove } from "@mui/icons-material";
import Button from "../Button";

type Props = {
  handleCloseMenu: () => void;
};

const DUMMY_PRODUCT = [
  {
    id: "1",
    img: "https://farktorcdn.com/Library/Upl/5500126/Product/722368MVİ-1.jpg",
    name: "Bebek Üç Araba Nakışlı Tulum - A-208 (0-9 Ay)",
    price: "129.90₺",
    linedPrice: "139.90₺",
  },
  {
    id: "2",
    img: "https://farktorcdn.com/Library/Upl/5500126/Product/722368MVİ-1.jpg",
    name: "Bebek Üç Araba Nakışlı Tulum - A-208 (0-9 Ay)",
    price: "129.90₺",
    linedPrice: "139.90₺",
  },
  {
    id: "3",
    img: "https://farktorcdn.com/Library/Upl/5500126/Product/722368MVİ-1.jpg",
    name: "Bebek Üç Araba Nakışlı Tulum - A-208 (0-9 Ay)",
    price: "129.90₺",
    linedPrice: "139.90₺",
  },
  {
    id: "4",
    img: "https://farktorcdn.com/Library/Upl/5500126/Product/722368MVİ-1.jpg",
    name: "Bebek Üç Araba Nakışlı Tulum - A-208 (0-9 Ay)",
    price: "129.90₺",
    linedPrice: "139.90₺",
  },
  {
    id: "5",
    img: "https://farktorcdn.com/Library/Upl/5500126/Product/722368MVİ-1.jpg",
    name: "Bebek Üç Araba Nakışlı Tulum - A-208 (0-9 Ay)",
    price: "129.90₺",
    linedPrice: "139.90₺",
  },
  {
    id: "6",
    img: "https://farktorcdn.com/Library/Upl/5500126/Product/722368MVİ-1.jpg",
    name: "Bebek Üç Araba Nakışlı Tulum - A-208 (0-9 Ay)",
    price: "129.90₺",
    linedPrice: "139.90₺",
  },
  {
    id: "7",
    img: "https://farktorcdn.com/Library/Upl/5500126/Product/722368MVİ-1.jpg",
    name: "Bebek Üç Araba Nakışlı Tulum - A-208 (0-9 Ay)",
    price: "129.90₺",
    linedPrice: "139.90₺",
  },
  {
    id: "8",
    img: "https://farktorcdn.com/Library/Upl/5500126/Product/722368MVİ-1.jpg",
    name: "Bebek Üç Araba Nakışlı Tulum - A-208 (0-9 Ay)",
    price: "129.90₺",
    linedPrice: "139.90₺",
  },
  {
    id: "9",
    img: "https://farktorcdn.com/Library/Upl/5500126/Product/722368MVİ-1.jpg",
    name: "Bebek Üç Araba Nakışlı Tulum - A-208 (0-9 Ay)",
    price: "129.90₺",
    linedPrice: "139.90₺",
  },
  {
    id: "10",
    img: "https://farktorcdn.com/Library/Upl/5500126/Product/722368MVİ-1.jpg",
    name: "Bebek Üç Araba Nakışlı Tulum - A-208 (0-9 Ay)",
    price: "129.90₺",
    linedPrice: "139.90₺",
  },
  {
    id: "11",
    img: "https://farktorcdn.com/Library/Upl/5500126/Product/722368MVİ-1.jpg",
    name: "Bebek Üç Araba Nakışlı Tulum - A-208 (0-9 Ay)",
    price: "129.90₺",
    linedPrice: "139.90₺",
  },
  {
    id: "12",
    img: "https://farktorcdn.com/Library/Upl/5500126/Product/722368MVİ-1.jpg",
    name: "Bebek Üç Araba Nakışlı Tulum - A-208 (0-9 Ay)",
    price: "129.90₺",
    linedPrice: "139.90₺",
  },
];

const ProductSelectMenu: React.FC<Props> = ({ handleCloseMenu }) => {
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

  const handleSelectProduct = (productId: string) => {
    const foundProduct = selectedProducts.find((item) => item === productId);
    if (foundProduct) {
      setSelectedProducts(
        selectedProducts.filter((item) => item !== productId),
      );
    } else {
      const tmpSelectedProducts = [...selectedProducts];
      tmpSelectedProducts.push(productId);
      setSelectedProducts(tmpSelectedProducts);
    }
  };

  return (
    <div className="absolute top-[-510px] left-0">
      <ClickAwayListener onClickAway={handleCloseMenu}>
        <div className="px-5">
          <MainCard elevation={8} content={false}>
            <div className="flex flex-col justify-between max-w-[400px] h-[500px] bg-white">
              <form action="">
                <div className="p-2 border-b-2 h-[55px]">
                  <div className="border-2 rounded-lg flex items-center py-1 px-2">
                    <Search className="text-gray-700" />
                    <input
                      type="text"
                      className="block w-full text-sm text-gray-700 outline-0 border-0 focus:outline-0 focus:border-0 mx-2 h-[24px]"
                      placeholder="Type a product name"
                      required
                      autoFocus
                    />
                  </div>
                </div>
              </form>
              <div className="p-2 grid grid-cols-4 gap-2 h-full overflow-y-auto">
                {DUMMY_PRODUCT.map((product) => (
                  <div
                    key={product.id}
                    className="group relative"
                    onClick={() => handleSelectProduct(product.id)}
                  >
                    {selectedProducts.find((item) => item === product.id) ? (
                      <div className="absolute top-[-6px] right-[-6px] w-6 h-6 bg-white rounded-full flex items-center justify-center shadow z-20">
                        <Check className="text-green-600" />
                      </div>
                    ) : null}

                    <div className="relative">
                      <img
                        src={product.img}
                        alt={product.name}
                        className="rounded"
                      />
                      <div className="opacity-0 transition group-hover:transition group-hover:opacity-100 absolute top-0 left-0 h-full w-full bg-[rgba(0,0,0,0.1)] flex items-center justify-center cursor-pointer">
                        <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center">
                          {selectedProducts.find(
                            (item) => item === product.id,
                          ) ? (
                            <Remove className="text-red-600" />
                          ) : (
                            <Check className="text-green-600" />
                          )}
                        </div>
                      </div>
                    </div>
                    <h6 className="text-xs text-gray-700 my-1">
                      {product.name}
                    </h6>
                    <div className="text-xs text-gray-500 line-through">
                      {product.linedPrice}
                    </div>
                    <div className="text-xs text-gray-700">{product.price}</div>
                  </div>
                ))}
              </div>
              <div className="p-2 border-t-2 flex items-center justify-end h-[55px]">
                <Button
                  title={`Send (${selectedProducts.length}) products`}
                  size="sm"
                  type="button"
                  className="me-2"
                  disabled={!selectedProducts.length}
                />
                <Button
                  title="Send fast purchase link"
                  size="sm"
                  type="button"
                  disabled={!selectedProducts.length}
                />
              </div>
            </div>
          </MainCard>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default memo(ProductSelectMenu);
