import React, { ButtonHTMLAttributes, HTMLAttributes, memo } from "react";
import { CircularProgress } from "@mui/material";

type Props = {
  title: string | JSX.Element;
  type: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  onClick?: () => void;
  className?: string;
  variant?: "filled" | "outlined" | "danger";
  size?: "sm" | "md";
  isLoading?: boolean;
  disabled?: boolean;
};
const Button: React.FC<Props> = ({
  title,
  type,
  onClick,
  className,
  variant = "filled",
  size = "md",
  isLoading,
  disabled,
}) => {
  const filled = "bg-biens border-transparent text-white hover:bg-sky-700";
  const outlined =
    "bg-white border-biens text-biens hover:bg-biens hover:text-white";
  const danger = "bg-red-600  border-transparent text-white hover:bg-red-700";
  const disable = "bg-gray-400 cursor-not-allowed hover:bg-gray-400";
  return (
    <button
      className={`${size === "md" ? "py-2 px-4" : "py-1 px-2"} flex items-center border-2 rounded font-bold transition duration-200 hover:transition hover:duration-200 text-sm h-fit ${variant === "outlined" ? outlined : variant === "danger" ? danger : filled} ${isLoading ? "opacity-80 cursor-not-allowed" : disabled ? disable : ""} ${className || ""}`}
      onClick={onClick}
      type={type}
      disabled={isLoading || disabled}
    >
      {isLoading ? (
        <CircularProgress
          className={`me-2 my-auto`}
          sx={{
            color: variant === "outlined" ? "#00A2E8" : "#FFFFFF",
          }}
          size={18}
        />
      ) : null}
      {title}
    </button>
  );
};

export default memo(Button);
