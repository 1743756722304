import React, { memo } from "react";
import { ClickAwayListener } from "@mui/material";
import MainCard from "./MainCard";
import EmojiPicker, { EmojiClickData, SkinTones } from "emoji-picker-react";

type Props = {
  handleCloseMenu: () => void;
  onEmojiClick: (emojiObject: EmojiClickData) => void;
};
const EmojiMenu: React.FC<Props> = ({ handleCloseMenu, onEmojiClick }) => {
  return (
    <div className="absolute top-[-460px] left-0">
      <ClickAwayListener onClickAway={handleCloseMenu}>
        <div className="px-5">
          <MainCard elevation={8} content={false}>
            <EmojiPicker
              onEmojiClick={onEmojiClick}
              defaultSkinTone={SkinTones.NEUTRAL}
              lazyLoadEmojis={true}
            />
          </MainCard>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default memo(EmojiMenu);
