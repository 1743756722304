import React, {
  ChangeEventHandler,
  DragEventHandler,
  useRef,
  useState,
} from "react";
import Dialog from "./index";
import { CloudUpload } from "@mui/icons-material";
import { Clear } from "@mui/icons-material";
import Button from "../Button";
import { toast } from "react-toastify";
import {
  REQUEST_UPDATE_COMPANY_PHOTO,
  REQUEST_UPDATE_USER_PHOTO,
} from "../../api/requests";
import { useDispatch } from "react-redux";
import { setCompanyPhoto, setUserPhoto } from "../../store/slices/authSlice";

type Props = {
  closeDialog: () => void;
  type: "company" | "user";
};
const UploadPhotoDialog: React.FC<Props> = ({ closeDialog, type }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isFileDragged, setIsFileDragged] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const dialogRef = useRef<HTMLDivElement>(null);
  const handleCloseWithAnimation = () => {
    dialogRef.current?.classList.add("animate-scale-down-anim");
    setFile(null);
    setFileUrl("");
    setIsFileDragged(false);
    setTimeout(() => {
      closeDialog();
    }, 200);
  };

  const handleUpload = async () => {
    if (!file) {
      return;
    }
    setIsUploading(true);

    // Create form data
    const formData = new FormData();
    formData.append("file", file);

    let request;
    if (type === "company") {
      request = REQUEST_UPDATE_COMPANY_PHOTO(formData);
    } else {
      request = REQUEST_UPDATE_USER_PHOTO(formData);
    }

    request
      .then((res) => {
        const payload = {
          photoKey: res.data.data.key,
          photoUrl: res.data.data.url,
        };
        dispatch(
          type === "company" ? setCompanyPhoto(payload) : setUserPhoto(payload),
        );
        handleCloseWithAnimation();
      })
      .catch((err) => {
        console.error("Error uploading file:", err);
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  const handleFileSelect: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    const files = e.target.files;
    if (files?.length) {
      const file = files[0];

      if (
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
      ) {
        const filePath = URL.createObjectURL(file);
        setFile(file);
        setFileUrl(filePath);
      } else {
        toast.error("file type is not supported");
        setIsFileDragged(false);
      }
    }
  };

  const dropHandler: DragEventHandler<HTMLDivElement> = (ev) => {
    ev.preventDefault();

    const files = ev.dataTransfer.files;
    const droppedFile = files[0];

    if (files.length) {
      const droppedItem = files[0];

      if (
        droppedItem.type === "image/png" ||
        droppedItem.type === "image/jpeg" ||
        droppedItem.type === "image/jpg"
      ) {
        const filePath = URL.createObjectURL(droppedFile);
        setFile(droppedFile);
        setFileUrl(filePath);
      } else {
        toast.error("file type is not supported");
        setIsFileDragged(false);
      }
    }
  };

  return (
    <Dialog>
      <div
        ref={dialogRef}
        className="bg-white rounded-lg px-4 py-2 min-w-[25vw] animate-scale-anim flex flex-col"
      >
        <div className="flex items-center justify-between border-b-2 pb-2 mb-2">
          <h3 className="font-bold">
            {type === "company"
              ? "Upload new company photo"
              : "Upload new user photo"}
          </h3>
          <button
            className="hover:bg-gray-200 rounded-lg"
            onClick={handleCloseWithAnimation}
          >
            <Clear className="text-gray-700" />
          </button>
        </div>

        {fileUrl ? (
          <div className="flex flex-col items-center justify-center mt-5">
            <img
              src={fileUrl}
              alt={"new-upload"}
              className="w-[120px] h-[120px] rounded-full border-2"
            />
            <Button
              title="Upload photo"
              type="button"
              size="sm"
              className="w-full mt-5 justify-center"
              onClick={handleUpload}
              isLoading={isUploading}
            />
          </div>
        ) : (
          <>
            <div
              id="drop_zone"
              onDrop={dropHandler}
              onDragOver={(e) => {
                e.preventDefault();
                setIsFileDragged(true);
              }}
              onDragLeave={() => setIsFileDragged(false)}
              className={`border-2 border-dashed w-full rounded-lg mt-5 py-5 flex flex-col items-center justify-center ${isFileDragged ? "border-sky-600 bg-sky-100" : ""}`}
            >
              <CloudUpload className="text-gray-400" sx={{ fontSize: 60 }} />
              <h4 className="text-gray-500 font-bold">
                Drag and drop image here
              </h4>
              <h4 className="text-gray-500 font-bold">or</h4>
              <input
                type="file"
                className="hidden"
                ref={fileInputRef}
                onChange={handleFileSelect}
              />
              <Button
                title="Choose from files"
                type="button"
                className="mt-2"
                size="sm"
                onClick={() => fileInputRef.current?.click()}
              />
            </div>
            <span className="text-gray-500 text-xs">
              Supported file formats JPG, JPEG, PNG
            </span>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default UploadPhotoDialog;
