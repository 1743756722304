import React from "react";

type Props = {
  children: JSX.Element;
};
const Dialog: React.FC<Props> = ({ children }) => {
  return (
    <div className="h-screen w-screen bg-[rgba(0,0,0,0.3)] fixed top-0 left-0 flex items-start justify-center p-10 animate-opacity-anim z-50">
      {children}
    </div>
  );
};

export default Dialog;
