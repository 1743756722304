import React, { memo, useState } from "react";
import { ClickAwayListener } from "@mui/material";
import MainCard from "./MainCard";
import { Search, Discount, Check } from "@mui/icons-material";
import Button from "../Button";

type Props = {
  handleCloseMenu: () => void;
};

const DUMMY_DISCOUNTS = [
  {
    id: "1",
    name: "15 dakika içinde satın alımlarda %15 indirim.",
    description: "Bu indirim diğer indirimlerle birleştirilemez",
  },
  {
    id: "2",
    name: "Yenidoğan kategorisine özel %5 indirim",
    description: "Bu indirim diğer indirimlerle birleştirilemez",
  },
  {
    id: "3",
    name: "Kargo bedava",
    description:
      "Bu indirim kodunu özel 300 TL ve üzeri alışverişinize kargo bedava!",
  },
];

const DiscountsMenu: React.FC<Props> = ({ handleCloseMenu }) => {
  const [selectedDiscount, setSelectedDiscount] = useState("");

  const handleSelectDiscount = (discountId: string) => {
    setSelectedDiscount(discountId);
  };

  return (
    <div className="absolute top-[-510px] left-0">
      <ClickAwayListener onClickAway={handleCloseMenu}>
        <div className="px-5">
          <MainCard elevation={8} content={false}>
            <div className="flex flex-col justify-between max-w-[400px] h-[500px] bg-white">
              <form action="">
                <div className="p-2 border-b-2 h-[55px]">
                  <div className="border-2 rounded-lg flex items-center py-1 px-2">
                    <Search className="text-gray-700" />
                    <input
                      type="text"
                      className="block w-full text-sm text-gray-700 outline-0 border-0 focus:outline-0 focus:border-0 mx-2 h-[24px]"
                      placeholder="Type a discount name"
                      required
                      autoFocus
                    />
                  </div>
                </div>
              </form>
              <div className="p-2 h-full overflow-y-auto">
                {DUMMY_DISCOUNTS.map((discount) => (
                  <div
                    key={discount.id}
                    onClick={() => handleSelectDiscount(discount.id)}
                    className="relative border-2 rounded p-2 flex items-center transition hover:transition hover:bg-gray-50 cursor-pointer mb-2"
                  >
                    {selectedDiscount === discount.id ? (
                      <div className="absolute top-[-9px] right-[-6px] w-6 h-6 bg-white rounded-full flex items-center justify-center shadow z-20">
                        <Check className="text-green-600" />
                      </div>
                    ) : null}

                    <Discount className="text-gray-700 me-2" />
                    <div>
                      <h6 className="text-xs text-gray-700 font-bold pe-4">
                        {discount.name}
                      </h6>
                      <div className="text-xs text-gray-700">
                        {discount.description}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="p-2 border-t-2 flex items-center justify-end h-[55px]">
                <Button
                  title="Send"
                  size="sm"
                  type="button"
                  disabled={!selectedDiscount}
                />
              </div>
            </div>
          </MainCard>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default memo(DiscountsMenu);
