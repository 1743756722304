import { Clear } from "@mui/icons-material";
import Dialog from "./index";
import React, { useRef } from "react";
import Button from "../Button";
import { useDispatch, useSelector } from "react-redux";
import { Company, setCompanyChatConfig } from "../../store/slices/authSlice";
import { RootState } from "../../store/store";
import { DEFAULT_CHAT_CONFIG } from "../../store/defaults";

type Props = {
  setChatConfig: (newChatConfig: Company["chatConfig"]) => void;
  closeDialog: () => void;
};

const ResetChatConfigDialog: React.FC<Props> = ({
  setChatConfig,
  closeDialog,
}) => {
  const { company } = useSelector((state: RootState) => state.auth);
  const dialogRef = useRef<HTMLDivElement>(null);
  const handleCloseWithAnimation = () => {
    dialogRef.current?.classList.add("animate-scale-down-anim");
    setTimeout(() => {
      closeDialog();
    }, 200);
  };

  const handleReset = (isHardReset: boolean) => {
    if (isHardReset) {
      setChatConfig(DEFAULT_CHAT_CONFIG);
    } else {
      setChatConfig(company.chatConfig);
    }
    handleCloseWithAnimation();
  };

  return (
    <Dialog>
      <div
        ref={dialogRef}
        className="bg-white rounded-lg px-4 py-2 min-w-[25vw] animate-scale-anim"
      >
        <div className="flex items-center justify-between border-b-2 pb-2 mb-2">
          <h3 className="font-bold">Are you sure to reset ?</h3>
          <button
            className="hover:bg-gray-200 rounded-lg"
            onClick={handleCloseWithAnimation}
          >
            <Clear className="text-gray-700" />
          </button>
        </div>
        <div>
          <span className="text-gray-700 text-sm">
            This operation can not be revert
          </span>
        </div>
        <div className="mt-5">
          <Button
            title="Reset my changes"
            type="button"
            size="sm"
            className="w-full"
            variant="outlined"
            onClick={() => handleReset(false)}
          />
          <Button
            title={
              <div>
                Hard reset to{" "}
                <span className="font-biens font-bold">biens.ai</span> defaults
              </div>
            }
            type="button"
            size="sm"
            className="w-full mt-3"
            variant="danger"
            onClick={() => handleReset(true)}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ResetChatConfigDialog;
