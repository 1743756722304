import axios, { AxiosError } from "axios";
import { AuthState, logout } from "../store/slices/authSlice";
import { EnhancedStore } from "@reduxjs/toolkit";

type Store = EnhancedStore<{ auth: AuthState }>;
function setupAxios(store: Store) {
  // REQUEST
  axios.interceptors.request.use((config) => {
    const { token } = store.getState().auth;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  // RESPONSE
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (err: AxiosError) => {
      if (err.response?.status === 401) {
        // Invalid token
        store.dispatch(logout());
      } else {
        return Promise.reject(err);
      }
    },
  );
}

export default setupAxios;
