const enTranslations = {
  login_header: "Log In",
  dont_have_an_account: "Don't have an account yet?",
  register: "Register",
  email_input: "Email",
  email_input_placeholder: "email@gmail.com",
  password_input: "Password",
  password_input_placeholder: "••••••••",
  did_you_forgot_password: "Forgot your password?",
  reset_password: "Reset",
  reset_password_header: "Reset Your Password",
  back_page: "Back",
  login_page: "login page",
  we_will_send_reset_link: "We will send a reset link to your email address.",
  send_button: "Send",
  thanks_for_interest: "Thank you for your interest.",
  thanks_for_interest_desc:
    "We would like to have an initial discussion with you to better understand our customers' needs and provide the most suitable solutions.\n\nBefore accessing our services, we prioritize having a demo presentation with you to better understand your needs. This discussion will help us identify your needs and provide you with the most suitable solution.\n\nTo request a demo, please contact us using one of the contact information below.\n\n",
  request_demo: "Request a Demo",
  web: "Web",
  whatsapp: "Whatsapp",
  facebook: "Facebook",
  instagram: "Instagram",
  telegram: "Telegram",
};

export default enTranslations;
