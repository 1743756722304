import { Outlet } from "react-router-dom";

const LoginLayout = () => {
  return (
    <div className="lg:h-screen lg:w-screen bg-sky-50 flex items-center justify-center">
      <b className="hidden lg:block text-biens absolute top-5 left-5 text-3xl font-extrabold font-biens">
        biens.ai
      </b>
      <Outlet />
    </div>
  );
};

export default LoginLayout;
