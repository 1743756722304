export const ChartPastOptions = [
  {
    label: "7 gün",
    value: "7",
  },
  {
    label: "14 gün",
    value: "14",
  },
  {
    label: "30 gün",
    value: "30",
  },
];
