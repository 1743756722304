import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import React from "react";
import { Company } from "../../store/slices/authSlice";
import ExampleRight from "../../assets/images/example-store-right.png";
import ExampleLeft from "../../assets/images/example-store-left.png";

type Props = {
  hM: number;
  vM: number;
  dP: Company["chatConfig"]["dP"];
};
const MockIcon: React.FC<Props> = ({ hM, vM, dP }) => {
  const {
    company: { companyPhotoUrl },
  } = useSelector((state: RootState) => state.auth);

  return (
    <div
      className={`relative bg-gray-100 h-[600px] w-[400px] border-gray-600 shadow-lg`}
    >
      <img
        src={dP === "r" ? ExampleRight : ExampleLeft}
        alt="demo-store-image"
        className={`absolute top-0 left-0 h-[600px] w-[400px] object-cover blur-[2px] ${dP === "r" ? "object-right-bottom" : "object-left-bottom"}`}
      />
      <div
        className="biens-chatbot-icon-container"
        style={{
          right: dP === "r" ? (Number(hM) > -1 ? Number(hM) : 40) : "unset",
          left: dP === "l" ? (Number(hM) > -1 ? Number(hM) : 40) : "unset",
          bottom: Number(vM) > -1 ? Number(vM) : 40,
        }}
      >
        <img src={companyPhotoUrl} alt="chat-bot" className="chatbot-img" />
      </div>
    </div>
  );
};

export default MockIcon;
