import { Company } from "./slices/authSlice";

export const DEFAULT_CHAT_CONFIG: Company["chatConfig"] = {
  hBg: "#FFFFFF",
  cBg: "#f9fafb",
  asTc: "#000000",
  osTc: "#757575",
  iBg: "#FFFFFF",
  diBg: "#ececec",
  iTc: "#0a0a0a",
  iT: "Please write your message",
  dSIc: "#757575",
  aSIc: "#00A2E8",
  rmBg: "#FFFFFF",
  rmTc: "#0a0a0a",
  rmDc: "#757575",
  smBg: "#e0f2fe",
  smTc: "#0a0a0a",
  smDc: "#757575",
  dP: "r",
  vM: 40,
  hM: 40,
};
