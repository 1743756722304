export const conversationMenuMobileToggle = () => {
  const conversationMenu = document.getElementById("conversation-menu");

  if (conversationMenu) {
    const isClosed = conversationMenu?.classList.contains("-translate-x-full");
    if (isClosed) {
      conversationMenu?.classList.remove("-translate-x-full");
    } else {
      conversationMenu?.classList.add("-translate-x-full");
    }
  }
};

export const settingsMenuMobileToggle = () => {
  const conversationMenu = document.getElementById("settings-menu");

  if (conversationMenu) {
    const isClosed = conversationMenu?.classList.contains("-translate-x-full");
    if (isClosed) {
      conversationMenu?.classList.remove("-translate-x-full");
    } else {
      conversationMenu?.classList.add("-translate-x-full");
    }
  }
};
