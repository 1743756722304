import { RefObject, useEffect } from "react";

const useOutsideClickDetector = (
  ref: RefObject<HTMLElement>,
  callback: () => void,
) => {
  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback();
    }
  };

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      handleClickOutside(event);
    };

    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        callback();
      }
    };

    document.addEventListener("keyup", handleEscape);
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("keyup", handleEscape);
    };
  }, [ref, callback]);
};

export default useOutsideClickDetector;
