import moment from "moment";
import { Search } from "@mui/icons-material";
import Button from "../Button";
import FilterButton from "./FilterButton";
import ProfilePhoto from "../ProfilePhoto";
import Skeleton from "@mui/material/Skeleton";
import CustomerSource from "../CustomerSource";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
// import { useState } from "react";
// import CustomerInfo from "./CustomerInfo";
// import { Customer } from "../../store/slices/customersSlice";

const CustomerTable = () => {
  /*
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    null,
  );
   */
  const { customers, isLoading } = useSelector(
    (state: RootState) => state.customers,
  );

  return (
    <div className="w-full h-full shadow rounded-lg bg-white">
      {/*
        selectedCustomer ? (
        <CustomerInfo
          customer={selectedCustomer}
          handleClose={() => setSelectedCustomer(null)}
        />
      ) : null
         */}
      <div className="p-2 flex items-center bg-white rounded-t-lg">
        <input
          type="text"
          placeholder="Search by customer name"
          className="w-full border-2 p-2 rounded-lg outline-0 border-0"
        />
        <Button title={<Search />} type={"button"} className="mx-2" />
        <FilterButton />
      </div>

      <div className="p-2 overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-200 text-sm">
            <tr className="rounded-t-lg">
              <th className="p-2 py-3 font-bold text-start rounded-tl-lg">
                Customer
              </th>
              <th className="p-2 font-bold text-start">Channel</th>
              <th className="p-2 font-bold text-start">Language</th>
              <th className="p-2 font-bold text-start rounded-tr-lg">
                Last view
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading
              ? new Array(10).fill(1).map((item, i) => (
                  <tr
                    key={i}
                    className={`${i % 2 === 0 ? "bg-white" : "bg-gray-100"} border-b-2 cursor-pointer text-gray-700`}
                  >
                    <td className="p-2 flex items-center">
                      <Skeleton variant="circular" width={36} height={36} />
                      <Skeleton
                        variant="text"
                        sx={{
                          fontSize: "1rem",
                          width: "calc(100% - 60px)",
                          marginLeft: "0.5em",
                        }}
                      />
                    </td>
                    <td className="p-2">
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    </td>
                    <td className="p-2">
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    </td>
                    <td className="p-2">
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    </td>
                  </tr>
                ))
              : customers.map((customer, i) => (
                  <tr
                    key={i}
                    className={`${i % 2 === 0 ? "bg-white" : "bg-gray-100"} border-b-2 cursor-pointer text-gray-700`}
                    // onClick={() => setSelectedCustomer(customer)}
                  >
                    <td className="p-2 flex items-center">
                      <ProfilePhoto
                        photoUrl={customer.photoUrl}
                        name={customer.name}
                      />
                      {customer.name.slice(0, 18)}
                    </td>
                    <td className="p-2">
                      <div className="flex items-center">
                        <CustomerSource
                          source={customer.source}
                          textClassName={"text-gray-700"}
                        />
                      </div>
                    </td>
                    <td className="p-2 text-xs">{customer.language}</td>
                    <td className="p-2 text-xs">
                      {moment(customer.lastView).fromNow()}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CustomerTable;
