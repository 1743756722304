import Dialog from "./index";
import React, { FormEventHandler, useRef, useState } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import { Clear } from "@mui/icons-material";
import Button from "../Button";
import { REQUEST_ADD_TEAM_MEMBER } from "../../api/requests";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { addMember, Team } from "../../store/slices/teamSlice";
import { MemberRow } from "../../pages/Team";

type Props = {
  closeDialog: () => void;
  member?: MemberRow;
};

type PermissionOptions = {
  label: string;
  value: Team["permission"];
  description: string;
};

const PERMISSIONS: PermissionOptions[] = [
  {
    label: "Admin",
    value: "admin",
    description:
      "Can reach all data, can add, edit or view team members, can change company settings",
  },
  {
    label: "Executive",
    value: "executive",
    description: "Can reach analytics, view messages, team members",
  },
  {
    label: "Agent",
    value: "agent",
    description: "Can reach messages, reply messages",
  },
];

const NewMemberDialog: React.FC<Props> = ({ closeDialog, member }) => {
  const dispatch = useDispatch();
  const dialogRef = useRef<HTMLDivElement>(null);
  const [name, setName] = useState(member?.name || "");
  const [email, setEmail] = useState(member?.email || "");
  const [selectedPermission, setSelectedPermission] = useState<
    Team["permission"]
  >(member?.permission || "agent");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const handleCloseWithAnimation = () => {
    dialogRef.current?.classList.add("animate-scale-down-anim");
    setTimeout(() => {
      closeDialog();
    }, 200);
  };
  useOutsideClick(dialogRef, handleCloseWithAnimation);

  const handleAddTeamMember: FormEventHandler = (e) => {
    e.preventDefault();
    setIsSubmitLoading(true);
    REQUEST_ADD_TEAM_MEMBER(name, email, selectedPermission)
      .then((res) => {
        toast.success(
          "Team member successfully add. The password forwarding to provided email address.",
        );
        const createdMember = res.data.data;
        dispatch(
          addMember({
            _id: createdMember._id,
            name: createdMember.name,
            email: createdMember.email,
            permission: createdMember.permission,
          }),
        );
        handleCloseWithAnimation();
      })
      .catch((err) => {
        toast.error("Error happened when adding new member");
      });
  };

  return (
    <Dialog>
      <div
        ref={dialogRef}
        className="bg-white rounded-xl px-4 py-2 min-w-[25vw] animate-scale-anim"
      >
        <button
          className="hover:bg-gray-200 rounded-lg absolute top-4 right-4"
          onClick={handleCloseWithAnimation}
        >
          <Clear className="text-gray-700" />
        </button>
        <form onSubmit={handleAddTeamMember} className="mt-5">
          <div className="sm:col-span-3">
            <label
              htmlFor="name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Name
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="name"
                id="name"
                autoComplete="given-name"
                placeholder="Name"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
                className="block w-full rounded-md border-0 outline-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-biens sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-3 mt-2">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email
            </label>
            <div className="mt-2">
              <input
                type="email"
                name="email"
                id="email"
                autoComplete="email"
                placeholder="example@example.com"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
                className="block w-full rounded-md border-0 outline-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-biens sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <fieldset className="mt-2">
            <legend className="text-sm font-semibold leading-6 text-gray-900">
              Permission
            </legend>
            <div className="mt-2 space-y-6">
              {PERMISSIONS.map((permission, i) => (
                <div key={i.toString()} className="flex items-center gap-x-3">
                  <input
                    id={permission.value}
                    name={permission.value}
                    type="radio"
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    checked={selectedPermission === permission.value}
                    onChange={() => setSelectedPermission(permission.value)}
                  />
                  <div className="text-sm leading-6">
                    <label
                      htmlFor={permission.value}
                      className="font-medium text-gray-900"
                    >
                      {permission.label}
                    </label>
                    <p className="text-gray-500">{permission.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </fieldset>

          <div className="mt-5">
            <Button
              title={member ? "Update member" : "Add member"}
              onClick={() => null}
              type="submit"
              size="sm"
              isLoading={isSubmitLoading}
            />
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default NewMemberDialog;
