const WebIntegrations = () => {
  return (
    <div className="lg:container lg:mx-auto px-3 py-4 pt-[calc(50px+1rem)] lg:pt-4 h-screen">
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 my-5">
        web integrations
      </div>
    </div>
  );
};

export default WebIntegrations;
