import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import LoginLayout from "./layout/LoginLayout";
import ForgotPassword from "./pages/ForgotPassword";
import Register from "./pages/Register";
import DashboardLayout from "./layout/DashboardLayout";
import Analytics from "./pages/Analytics";
import Messages from "./pages/Messages";
import Customers from "./pages/Customers";
import Integrations from "./pages/Integrations";
import ChatConfig from "./pages/ChatConfig";
import { useSelector } from "react-redux";
import { RootState } from "./store/store";
import WebIntegrations from "./pages/Integrations/Web";
import SettingsLayout from "./layout/SettingsLayout";
import UserSettings from "./pages/Settings/User";
import CompanySettings from "./pages/Settings/Company";
import Subscription from "./pages/Settings/Subscription";
import Usage from "./pages/Settings/Usage";
import Help from "./pages/Help";
import MessagesLayout from "./layout/MessagesLayout";
import { ToastContainer } from "react-toastify";
import TelegramIntegration from "./pages/Integrations/Telegram";
import Team from "./pages/Team";
import AISettings from "./pages/Settings/AI";
const Navigation = () => {
  const { token } = useSelector((state: RootState) => state.auth);
  return (
    <BrowserRouter>
      <ToastContainer />
      {!token ? (
        <Routes>
          <Route path="/" element={<LoginLayout />}>
            <Route path="/" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/sign-up" element={<Register />} />
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      ) : (
        <Routes>
          <Route path={"/dashboard/"} element={<DashboardLayout />}>
            <Route path="/dashboard/analytics" element={<Analytics />} />
            <Route path="/dashboard/messages" element={<MessagesLayout />}>
              <Route
                path="/dashboard/messages/:chatId"
                element={<Messages />}
              />
            </Route>
            <Route path="/dashboard/customers" element={<Customers />} />
            <Route path="/dashboard/integrations" element={<Integrations />} />
            <Route path="/dashboard/team" element={<Team />} />
            <Route
              path="/dashboard/integrations/web"
              element={<WebIntegrations />}
            />
            <Route
              path="/dashboard/integrations/telegram"
              element={<TelegramIntegration />}
            />
            <Route path="/dashboard/chat-config" element={<ChatConfig />} />
            <Route path="/dashboard/settings" element={<SettingsLayout />}>
              <Route path="/dashboard/settings" element={<UserSettings />} />
              <Route
                path="/dashboard/settings/company"
                element={<CompanySettings />}
              />
              <Route path="/dashboard/settings/ai" element={<AISettings />} />
              <Route
                path="/dashboard/settings/subscription"
                element={<Subscription />}
              />
              <Route path="/dashboard/settings/usage" element={<Usage />} />
            </Route>
            <Route path="/dashboard/help" element={<Help />} />
          </Route>
          <Route
            path="*"
            element={<Navigate to="/dashboard/analytics" replace />}
          />
        </Routes>
      )}
    </BrowserRouter>
  );
};

export default Navigation;
