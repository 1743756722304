import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import {
  DataUsage,
  Beenhere,
  ManageAccounts,
  Business,
  Psychology,
} from "@mui/icons-material";
import { settingsMenuMobileToggle } from "../utils";
import { ChevronLeft } from "@mui/icons-material";

const SETTINGS = [
  {
    label: "User",
    to: "/dashboard/settings",
    icon: <ManageAccounts />,
    activeIcon: <ManageAccounts className="text-biens" />,
  },
  {
    label: "Company",
    to: "/dashboard/settings/company",
    icon: <Business />,
    activeIcon: <Business className="text-biens" />,
  },
  {
    label: "AI Settings",
    to: "/dashboard/settings/ai",
    icon: <Psychology />,
    activeIcon: <Psychology className="text-biens" />,
  },
  {
    label: "Subscription",
    to: "/dashboard/settings/subscription",
    icon: <Beenhere />,
    activeIcon: <Beenhere className="text-biens" />,
  },
  {
    label: "Usage",
    to: "/dashboard/settings/usage",
    icon: <DataUsage />,
    activeIcon: <DataUsage className="text-biens" />,
  },
];
const SettingsLayout = () => {
  return (
    <div className="container mx-auto h-full lg:px-3 lg:py-4">
      <div className="flex w-full h-full">
        <div
          id="settings-menu"
          className="w-screen lg:w-[30%] h-dvh lg:h-full pt-[50px] lg:pt-0 fixed top-0 left-0 lg:relative transition-transform lg:translate-x-0 overflow-y-auto shadow rounded-tl-lg rounded-bl-lg bg-white z-40"
        >
          {SETTINGS.map((setting, i) => (
            <NavLink
              key={setting.to}
              end
              to={setting.to}
              className={({ isActive }) =>
                isActive
                  ? "text-gray-700 p-2 flex items-center text-biens bg-gray-200"
                  : "text-gray-700 p-2 hover:bg-gray-200 flex items-center transition hover:transition"
              }
              onClick={settingsMenuMobileToggle}
            >
              {({ isActive }) => (
                <>
                  {isActive ? setting.activeIcon : setting.icon}
                  <span
                    className={`ms-2 whitespace-nowrap text-sm font-bold ${isActive ? "text-biens" : ""}`}
                  >
                    {setting.label}
                  </span>
                </>
              )}
            </NavLink>
          ))}
        </div>
        <div className="bg-gray-100 w-full lg:w-[70%] lg:border-l-2 lg:shadow lg:rounded-tr-lg lg:rounded-br-lg p-2 pt-[calc(50px+0.5rem)] lg:pt-2 overflow-y-auto min-h-dvh lg:min-h-0">
          <div
            className="lg:hidden bg-white border-2 rounded-lg py-2 mb-2 flex items-center"
            onClick={settingsMenuMobileToggle}
          >
            <ChevronLeft className="text-gray-700" />
            <h3 className="text-gray-700 font-bold text-sm">Settings</h3>
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default SettingsLayout;
