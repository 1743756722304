import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import React, { useState } from "react";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
Chart.register(CategoryScale);

const getGradient = (ctx: any) => {
  let gradient = ctx.createLinearGradient(0, 0, 0, 120);
  gradient.addColorStop(0, "rgba(44, 115, 255, 0.4)");
  gradient.addColorStop(0.5, "rgba(44, 115, 255, 0.3)");
  gradient.addColorStop(1, "rgba(44, 115, 255, 0)");
  return gradient;
};

const options = {
  responsive: true,
  fill: true,
  borderColor: "#00A2E8",
  borderWidth: 2,
  height: 100,
  backgroundColor: function (context: any) {
    const chart = context.chart;
    const { ctx, chartArea } = chart;

    if (!chartArea) {
      // This case happens on initial chart load
      return null;
    }
    return getGradient(ctx);
  },
  maintainAspectRatio: false,
  bezierCurve: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          var label = context.dataset.label || "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += context.parsed.y;
          }
          return label;
        },
      },
      backgroundColor: "#edf3ff",
      borderColor: "grey",
      boxHeight: 10,
      borderWidth: 0.2,
      titleFont: {
        size: 12,
      },
      titleColor: "#2c73ff",
      bodyColor: "#2c73ff",
      bodyFont: {
        size: 12,
      },
      displayColors: false,
      caretSize: 2,
    },
  },
  interaction: {
    intersect: false,
  },
  elements: {
    point: {
      pointStyle: "circle",
      radius: 2,
    },
  },
  scales: {
    y: {
      max: 6,
      stacked: true,
      ticks: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
    x: {
      ticks: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
  },
};

type Props = {
  title: string;
  chartData: number[];
  diffCount: string;
};
const MiniChart: React.FC<Props> = ({ title, chartData, diffCount }) => {
  const allRanges = [
    {
      value: 7,
      label: "Son 7 gün",
    },
    {
      value: 14,
      label: "Last 14 days",
    },
    {
      value: 30,
      label: "Last 30 days",
    },
  ];
  const [dateMenu, setDateMenu] = useState(false);
  const [selectedRange, setSelectedRange] = useState(7);
  const data = {
    labels: chartData.map((item) => item),
    datasets: [
      {
        data: chartData,
        tension: 0.4,
      },
    ],
  };

  const handleSelectRange = (rangeValue: number) => {
    setSelectedRange(rangeValue);
    setDateMenu(false);
  };

  return (
    <div className="h-[180px] w-full flex flex-col items-center justify-between rounded-lg shadow p-2 bg-white relative">
      <div className="h-full w-full rounded-t-lg text-xs text-gray-700 px-2 flex justify-between items-center">
        <div className="font-bold">{title}</div>
        <div className="flex items-center">
          <div className="bg-biens py-1 px-3 rounded-lg text-white font-bold">
            {chartData.reduce((a, b) => a + b, 0).toLocaleString("tr-TR")}
          </div>
          <span className="ms-1 text-gray-500">{diffCount}</span>
        </div>
      </div>
      <div className="h-[120px] w-full">
        <Line
          data={data}
          options={{
            ...options,
            scales: {
              ...options.scales,
              y: {
                ...options.scales.y,
                max:
                  [...chartData].sort((a, b) => b - a)[0] * 1.1 === 0
                    ? 1
                    : [...chartData].sort((a, b) => b - a)[0] * 1.1,
              },
            },
          }}
        />
      </div>
      <button
        onClick={() => setDateMenu(!dateMenu)}
        className="h-full w-full rounded-t-lg text-xs flex items-center justify-center text-gray-700 cursor-pointer"
      >
        {allRanges.find((item) => item.value === selectedRange)?.label}{" "}
        {dateMenu ? (
          <ExpandLess className="text-biens" />
        ) : (
          <ExpandMore className="text-biens" />
        )}
      </button>
      {dateMenu ? (
        <div className="absolute w-full bottom-[-70px] rounded-lg flex flex-col shadow bg-white z-10">
          <button
            onClick={() =>
              handleSelectRange(
                allRanges.filter((item) => item.value !== selectedRange)[0]
                  .value,
              )
            }
            className="text-xs text-gray-700 border-b-2 p-2 hover:bg-gray-200 rounded-t-lg"
          >
            {allRanges.filter((item) => item.value !== selectedRange)[0].label}
          </button>
          <button
            onClick={() =>
              handleSelectRange(
                allRanges.filter((item) => item.value !== selectedRange)[1]
                  .value,
              )
            }
            className="text-xs text-gray-700 p-2 hover:bg-gray-200 rounded-b-lg"
          >
            {allRanges.filter((item) => item.value !== selectedRange)[1].label}
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default MiniChart;
