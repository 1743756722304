import Button from "../../components/Button";
import { Check } from "@mui/icons-material";

const SUBSCRIPTIONS = [
  {
    title: "Nano",
    description:
      "This plan offers a compact yet powerful set of features, suitable for small businesses or individuals looking to dip their toes into chatbot technology.",
    price: "$29.00",
    duration: "/month",
    features: [
      "Unlimited chats with human agents",
      "100 Conversations / Month",
      "GPT-4 Technology",
      "Unlimited Shopify Products",
    ],
  },
  {
    title: "Quantum",
    description:
      "The Quantum Bot plan introduces advanced features and capabilities, leveraging cutting-edge technologies to provide a more sophisticated chatbot solution for growing businesses.",
    price: "$99.00",
    duration: "/month",
    features: ["Everything in Nano plan", "1000 Conversations / Month"],
  },
  {
    title: "Cyber",
    description:
      "The Cyber Bot plan offers a comprehensive suite of features, incorporating AI-driven algorithms and cybernetic enhancements to deliver an intelligent and dynamic chatbot experience.",
    price: "$299.00",
    duration: "/month",
    features: [
      "Everything in Nano plan",
      "Everything in Quantum plan",
      "5000 Conversations / Month",
    ],
  },
  {
    title: "Hyper",
    description:
      "Designed for large enterprises, the Hyper Bot plan provides unparalleled performance and scalability, harnessing hyper-automation and hyper-intelligence to handle complex customer interactions with ease.",
    price: "Talk with sales",
    duration: "",
    features: [
      "We collaborate closely with you to understand your unique requirements and business goals. Our team of experts works hand-in-hand with you to design and develop a bespoke chatbot model tailored specifically to your needs.",
    ],
  },
];

const Subscription = () => {
  return (
    <div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        {SUBSCRIPTIONS.map((subscription, i) => (
          <div key={i} className="border-2 bg-white p-5 rounded-lg">
            <h3 className="text-gray-700 font-bold">{subscription.title}</h3>
            <span className="text-sm text-gray-500">
              {subscription.description}
            </span>
            <div className="flex items-center my-5">
              <h4 className="text-gray-700 font-bold text-xl">
                {subscription.price}
              </h4>
              <span className="text-gray-500 text-xs">
                {subscription.duration}
              </span>
            </div>
            <Button
              title="Subscribe"
              type="button"
              variant="outlined"
              size="sm"
              className="w-full"
            />
            <ul className="mt-5 ps-2">
              {subscription.features.map((feature, i) => (
                <li className="flex items-center" key={i}>
                  <Check className="text-biens me-1" />
                  <span className="text-gray-600 text-sm">{feature}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Subscription;
