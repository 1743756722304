import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Customer = {
  _id: string;
  name: string;
  photoUrl?: string;
  source: "web" | "instagram" | "telegram" | "messenger" | "whatsapp";
  language: string;
  country_code?: string;
  country_name?: string;
  city?: string;
  state?: string;
  createdAt: number;
  lastView: number;
  visitCount: number;
  timezone: string;
  deviceType: string;
  browserName?: string;
  email?: string;
  phoneNumber?: null;
  webIdentifier: string;
  lastMessage?: string;
  lastMessageTime?: number;
  lastMessageRole?: "assistant" | "role";
};

interface CustomersState {
  customers: Customer[];
  isLoading: boolean;
}

const initialState: CustomersState = {
  customers: [],
  isLoading: true,
};

export const customersSlice = createSlice({
  name: "conversations",
  initialState,
  reducers: {
    setCustomers: (state, action: PayloadAction<Customer[]>) => {
      state.customers = action.payload;
      state.isLoading = false;
    },
  },
});

export const { setCustomers } = customersSlice.actions;

export default customersSlice.reducer;
