import React, { ChangeEventHandler } from "react";

type Props = {
  labelText: string;
  descriptionText?: string;
  id: string;
  type: "text" | "password" | "email";
  placeholder: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  isRequired?: boolean;
};

const LoginInput: React.FC<Props> = ({
  labelText,
  descriptionText,
  id,
  type,
  placeholder,
  value,
  onChange,
  isRequired,
}) => {
  return (
    <div className="flex flex-col my-5">
      <label htmlFor={id}>
        {labelText}
        <b className="text-red-600 ms-1">{isRequired ? "*" : ""}</b>
      </label>
      {descriptionText ? (
        <span className="text-xs text-gray-500 mb-1">{descriptionText}</span>
      ) : null}
      <input
        className="border py-1 px-2 rounded mt-2"
        id={id}
        type={type}
        placeholder={placeholder}
        required={isRequired}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default LoginInput;
