import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import Button from "../../../components/Button";
import { QuestionMark } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  REQUEST_REMOVE_TELEGRAM_BOT,
  REQUEST_SET_TELEGRAM_BOT,
} from "../../../api/requests";
import { toast } from "react-toastify";
import { setTelegramStatus } from "../../../store/slices/authSlice";

const TelegramIntegration = () => {
  const dispatch = useDispatch();
  const {
    company: { isTelegram },
  } = useSelector((state: RootState) => state.auth);
  const [telegramBotId, setTelegramBotId] = useState(
    isTelegram ? "XXXXXXXXXX:XXXXXXXXX-XXXXXXXXXXXXXXXXXXXXXXXXX" : "",
  );
  const [isRequesting, setIsRequesting] = useState(false);

  const handleActivate = () => {
    if (!telegramBotId) {
      return;
    }
    setIsRequesting(true);
    REQUEST_SET_TELEGRAM_BOT(telegramBotId)
      .then(() => {
        dispatch(setTelegramStatus(true));
        setTelegramBotId("XXXXXXXXXX:XXXXXXXXX-XXXXXXXXXXXXXXXXXXXXXXXXX");
        toast.success("Telegram id successfully set");
      })
      .catch(() =>
        toast.error("Error happened!\nPlease check your telegram bot id"),
      )
      .finally(() => setIsRequesting(false));
  };

  const handleDeactive = () => {
    setIsRequesting(true);
    REQUEST_REMOVE_TELEGRAM_BOT()
      .then(() => {
        dispatch(setTelegramStatus(false));
        setTelegramBotId("");
        toast.success("Telegram integration successfully deactivated");
      })
      .catch(() => toast.error("Error happened when deactivating telegram."))
      .finally(() => setIsRequesting(false));
  };

  return (
    <div className="lg:container lg:mx-auto px-3 py-4 pt-[calc(50px+1rem)] lg:pt-4 h-screen">
      <div className="bg-white rounded-lg border-2 p-2">
        <h5 className="mb-5 font-bold">Telegram Integration</h5>
        <span className="text-gray-700 text-sm">
          Integration status is{" "}
          <span
            className={`font-bold ${isTelegram ? "text-green-600" : "text-red-600"}`}
          >
            {isTelegram ? "active" : "passive"}
          </span>
          <div className="my-5 flex flex-col">
            <label
              htmlFor="telegramBotId"
              className="font-bold flex items-center"
            >
              Telegram Bot Id{" "}
              <Tooltip
                title={
                  <div className="flex flex-col">
                    <span className="font-bold">
                      You dont have Telegram Bot Id ?
                    </span>
                    <br />
                    <span>
                      Please check this link:{" "}
                      <Link to={"/dashboard/help"} className="font-bold">
                        I dont have telegram bot id
                      </Link>
                    </span>
                  </div>
                }
              >
                <QuestionMark
                  sx={{ fontSize: 14 }}
                  className="text-biens ms-2"
                />
              </Tooltip>
            </label>
            <input
              type="text"
              id="telegramBotId"
              className={`w-full lg:w-[380px] mt-3 p-2 outline-0 border-2 rounded-lg ${isTelegram ? "bg-gray-300" : ""}`}
              placeholder={"XXXXXXXXXX:XXXXXXXXX-XXXXXXXXXXXXXXXXXXXXXXXXX"}
              disabled={isTelegram}
              value={telegramBotId}
              onChange={(e) => setTelegramBotId(e.target.value)}
            />
            <Button
              title={isTelegram ? "Deactivate" : "Activate"}
              type="button"
              className={`mt-3 w-max ${isTelegram ? "bg-red-600" : ""}`}
              onClick={isTelegram ? handleDeactive : handleActivate}
              isLoading={isRequesting}
            />
          </div>
        </span>
      </div>
    </div>
  );
};

export default TelegramIntegration;
