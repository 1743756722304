import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import { ChartData } from "chart.js";
import DropdownSelect from "../../DropdownSelect";
import { ChartPastOptions } from "../constants";

type Props = {
  title: string;
  data: ChartData<"bar">;
  direction: "x" | "y";
};
const BarChart: React.FC<Props> = ({ title, data, direction }) => {
  const [chartDuration, setChartDuration] = useState("7");

  return (
    <div className="bg-white rounded-lg shadow p-4">
      <div className="flex justify-between items-center">
        <span className="text-xs text-gray-700 mb-2 font-bold">{title}</span>
        <DropdownSelect
          label={"Last"}
          options={ChartPastOptions}
          selectedValue={chartDuration}
          handleChangeValue={setChartDuration}
        />
      </div>
      <Bar
        data={data}
        options={{
          indexAxis: direction,
          elements: {
            bar: {
              borderWidth: 0,
            },
          },
          responsive: true,
          maintainAspectRatio: true,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: function (context: any) {
                  var label = context.dataset.label || "";

                  if (label) {
                    label += ": ";
                  }
                  if (context.parsed.y !== null) {
                    if (direction === "x") {
                      label += context.parsed.y;
                    } else {
                      label += context.parsed.x;
                    }
                  }
                  return label;
                },
              },
              backgroundColor: "#edf3ff",
              borderColor: "grey",
              boxHeight: 10,
              borderWidth: 0.2,
              titleFont: {
                size: 12,
              },
              titleColor: "#2c73ff",
              bodyColor: "#2c73ff",
              bodyFont: {
                size: 12,
              },
              displayColors: false,
              caretSize: 2,
            },
          },
          interaction: {
            intersect: false,
            mode: direction,
          },
          scales: {
            y: {
              ticks: {
                display: true,
              },
              grid: {
                display: false,
              },
              stacked: true,
            },
            x: {
              ticks: {
                display: true,
                callback: function (value, index) {
                  if (
                    direction === "x" &&
                    data?.labels?.length &&
                    data.labels.length > index
                  ) {
                    const labelString = data.labels[index] as string;
                    if (!labelString.length) {
                      console.log("buranın lengthi yok", index, data);
                    }
                    return labelString.length > 12
                      ? `${labelString.slice(0, 12).trim()}...`
                      : labelString;
                  }
                  return value;
                },
              },
              grid: {
                display: false,
              },
              stacked: true,
            },
          },
        }}
      />
    </div>
  );
};

export default BarChart;
