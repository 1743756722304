import { useState } from "react";
import Button from "../Button";
import { FilterList } from "@mui/icons-material";
import MultipleSelect from "../MultipleSelect";
import { ActionMeta, MultiValue, Options } from "react-select";

const FilterButton = () => {
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [filteredSources, setFilteredSources] = useState<string[]>([]);
  const [filteredLocations, setFilteredLocations] = useState<string[]>([]);
  const allPossibleSources: Options<any> = [
    { value: "web", label: "Web" },
    {
      value: "instagram",
      label: "Instagram",
    },
    { value: "facebook", label: "Messenger" },
    { value: "telegram", label: "Telegram" },
    { value: "whatsapp", label: "Whatsapp" },
  ];
  const allPossibleLocations: Options<any> = [
    { value: "IST", label: "Istanbul/Turkey" },
    {
      value: "ANK",
      label: "Ankara/Turkey",
    },
    { value: "BRS", label: "Bursa/Turkey" },
  ];
  const handleChangeSourceFilter = (
    newValue: MultiValue<string>,
    actionMeta: ActionMeta<string>,
  ) => {
    const newFilteredSources = [...newValue];
    setFilteredSources(newFilteredSources);
  };
  const handleChangeLocationFilter = (
    newValue: MultiValue<string>,
    actionMeta: ActionMeta<string>,
  ) => {
    const newFilteredLocations = [...newValue];
    setFilteredLocations(newFilteredLocations);
  };

  const handleClearFilters = () => {
    setFilteredSources([]);
    setFilteredLocations([]);
  };

  return (
    <div className="relative">
      <Button
        title={
          <div className="flex items-center">
            <FilterList />
            <span className="text-xs font-bold ms-1">Filter</span>
          </div>
        }
        type={"button"}
        onClick={() => setIsFilterMenuOpen(true)}
      />
      {isFilterMenuOpen ? (
        <div className="absolute bg-white z-10 p-5 top-[50px] right-0 shadow rounded-lg">
          <div>
            <MultipleSelect
              value={filteredSources}
              onChange={handleChangeSourceFilter}
              options={allPossibleSources}
              label={"Channel"}
            />
          </div>
          <div className="my-4">
            <MultipleSelect
              value={filteredLocations}
              onChange={handleChangeLocationFilter}
              options={allPossibleLocations}
              label={"Location"}
            />
          </div>
          <div className="flex items-center justify-between mt-2">
            <Button
              title={"Clear"}
              onClick={handleClearFilters}
              type={"button"}
              variant="outlined"
              size="sm"
            />
            <Button
              title={"Filtrele"}
              onClick={() => setIsFilterMenuOpen(false)}
              type={"button"}
              size="sm"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FilterButton;
