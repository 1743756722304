import { Link } from "react-router-dom";
import Logo from "../../assets/images/biens-logo.png";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";

const Register = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-white rounded-lg shadow-2xl w-screen h-screen lg:w-[20vw] lg:min-w-[350px] lg:h-auto">
      <div className="flex p-5">
        <img src={Logo} alt="biens-logo" className="w-[50px] h-[50px]" />
        <div className="flex flex-col ms-2 justify-between">
          <h1 className="font-bold">{t("thanks_for_interest")}</h1>
          <span className="text-xs">
            {t("back_page")}
            <Link
              to={"/sign-in"}
              className="text-biens transition hover:transition hover:text-sky-700 ms-1"
            >
              {t("login_page")}
            </Link>
          </span>
        </div>
      </div>
      <hr />
      <div className="text-gray-700 p-5 whitespace-break-spaces">
        {t("thanks_for_interest_desc")}
        <Button
          title={t("request_demo")}
          type="submit"
          onClick={() => (window.location.href = "https://biens.ai/iletisim")}
        />
      </div>
    </div>
  );
};

export default Register;
