const trTranslations = {
  login_header: "Giriş Yap",
  dont_have_an_account: "Henüz hesabın yok mu?",
  register: "Kayıt ol",
  email_input: "Email",
  email_input_placeholder: "email@gmail.com",
  password_input: "Şifre",
  password_input_placeholder: "••••••••",
  did_you_forgot_password: "Şifreni mi unuttun?",
  reset_password: "Sıfırla",
  reset_password_header: "Şifreni Sıfırla",
  back_page: "Geri dön",
  login_page: "giriş sayfası",
  we_will_send_reset_link: "Email adresine sıfırlama linki göndereceğiz.",
  send_button: "Gönder",
  thanks_for_interest: "İlginiz için teşekkür ederiz.",
  thanks_for_interest_desc:
    "Müşterilerimizin ihtiyaçlarını en iyi şekilde karşılamak ve en uygun çözümleri sunabilmek adına, sizinle bir ön görüşme gerçekleştirmeyi arzu ediyoruz.\n\nFirmamızın hizmetlerine erişim sağlamadan önce, sizinle bir demo sunumu gerçekleştirmek ve ihtiyaçlarınızı daha iyi anlamak amacıyla bir görüşme yapmayı önemsiyoruz. Bu görüşme, ihtiyaçlarınızı belirlememize ve size en uygun çözümü sunmamıza yardımcı olacaktır.\n\nDemo talebinizi oluşturmak için lütfen aşağıdaki iletişim bilgilerimizden birini kullanarak bizimle iletişime geçin\n\n",
  request_demo: "Demo Talebi Oluştur",
  analytics: "Analitik",
  messages: "Mesajlar",
  team: "Takım",
  customers: "Müşteriler",
  integrations: "Kanallar",
  chat_config: "Görünüm",
  settings: "Ayarlar",
  help: "Yardım",
  write_a_message: "Bir mesaj yazın",
};

export default trTranslations;
