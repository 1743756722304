import React, { memo, useState } from "react";
import CustomerSource from "../CustomerSource";
import ProfilePhoto from "../ProfilePhoto";
import { AccountCircleOutlined, Menu } from "@mui/icons-material";
import { conversationMenuMobileToggle } from "../../utils";
import { MoreHoriz } from "@mui/icons-material";
import { Conversation } from "../../store/slices/conversationsSlice";
import Button from "../Button";
import AssignToAgent from "../Dialog/AssignToAgent";

type Props = {
  customer: Conversation;
};
const ChatHeader: React.FC<Props> = ({ customer }) => {
  const [assignToAgentDialog, setAssignToAgentDialog] = useState(false);
  return (
    <div className="bg-white py-2 px-5 border-b-2 flex items-center justify-between h-[60px]">
      {assignToAgentDialog ? (
        <AssignToAgent
          customerId={customer._id}
          closeDialog={() => setAssignToAgentDialog(false)}
        />
      ) : null}
      <div className="flex items-center">
        <button className="lg:hidden" onClick={conversationMenuMobileToggle}>
          <Menu className="text-gray-700 me-2" />
        </button>
        <ProfilePhoto name={customer.name} photoUrl={customer.photoUrl} />
        <div className="ms-2 w-fit flex flex-col">
          <h3 className="font-bold">{customer.name.slice(0, 18)}</h3>
          <div className="flex items-center">
            <CustomerSource source={customer.source} />
          </div>
        </div>
      </div>
      <Button
        title={
          <>
            <AccountCircleOutlined className="md:me-2" />
            <span className="hidden md:block">Assign to agent</span>
          </>
        }
        size="sm"
        type="button"
        className="w-fit"
        onClick={() => setAssignToAgentDialog(true)}
      />
    </div>
  );
};

export default memo(ChatHeader);
