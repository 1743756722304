import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DEFAULT_CHAT_CONFIG } from "../defaults";
import { Team } from "./teamSlice";

export type Rule = {
  _id: string;
  rule: string;
};

export interface Company {
  _id: string;
  companyName: string;
  companyPhotoKey?: string;
  companyPhotoUrl?: string;
  userName: string;
  userId: string;
  userEmail: string;
  userPhotoKey?: string;
  userPhotoUrl?: string;
  messageBalance: number;
  welcomeMessage: string;
  permission: Team["permission"];
  isDemo: boolean;
  allowedOrigins: string[];
  AIRules: Rule[];
  autoAssignBiens: boolean;
  isTelegram?: boolean;
  isWeb?: boolean;
  isMessenger?: boolean;
  isInstagram?: boolean;
  isWhatsapp?: boolean;
  chatConfig: {
    hBg: string; // header background
    cBg: string; // chat background
    asTc: string; // assistant text color
    osTc: string; // online status text color
    iBg: string; // input background
    diBg: string; // disabled input background
    iTc: string; // input text color
    iT: string; // input text
    dSIc: string; // disabled sent icon color
    aSIc: string; // sent icon color
    rmBg: string; // received message background color
    rmTc: string; // received message text color
    rmDc: string; // received message date color
    smBg: string; // send message background color
    smTc: string; // send message text color
    smDc: string; // send message date color
    dP: "l" | "r"; // display position
    vM: number; // bottom margin
    hM: number; // horizontal margin
  };
  createdAt: number;
}
export interface AuthState {
  company: Company;
  token?: string;
  isSocketOn: boolean;
}

const initialState: AuthState = {
  company: {
    _id: "",
    companyName: "",
    companyPhotoUrl: "",
    userName: "",
    userId: "",
    userEmail: "",
    messageBalance: 0,
    permission: "agent",
    allowedOrigins: [""],
    AIRules: [],
    autoAssignBiens: true,
    isDemo: false,
    welcomeMessage: "",
    chatConfig: DEFAULT_CHAT_CONFIG,
    createdAt: new Date().getTime(),
  },
  token: undefined,
  isSocketOn: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCompanyData: (state, action: PayloadAction<Company>) => {
      state.company = action.payload;
    },
    setCompanyPhoto: (
      state,
      action: PayloadAction<{ photoKey: string; photoUrl: string }>,
    ) => {
      state.company.companyPhotoKey = action.payload.photoKey;
      state.company.companyPhotoUrl = action.payload.photoUrl;
    },
    setUserPhoto: (
      state,
      action: PayloadAction<{ photoKey: string; photoUrl: string }>,
    ) => {
      state.company.userPhotoKey = action.payload.photoKey;
      state.company.userPhotoUrl = action.payload.photoUrl;
    },
    setCompanyName: (state, action: PayloadAction<string>) => {
      state.company.companyName = action.payload;
    },
    setUserName: (state, action: PayloadAction<string>) => {
      state.company.userName = action.payload;
    },
    setCompanyWelcomeMessage: (state, action: PayloadAction<string>) => {
      state.company.welcomeMessage = action.payload;
    },
    setCompanyChatConfig: (
      state,
      action: PayloadAction<Company["chatConfig"]>,
    ) => {
      state.company.chatConfig = action.payload;
    },
    setTelegramStatus: (state, action: PayloadAction<boolean>) => {
      state.company.isTelegram = action.payload;
    },
    setAutoAssignBiensStatus: (state, action: PayloadAction<boolean>) => {
      state.company.autoAssignBiens = action.payload;
    },
    setAIRules: (state, action: PayloadAction<Rule[]>) => {
      state.company.AIRules = action.payload;
    },
    updateAIRule: (
      state,
      action: PayloadAction<{ ruleId: string; rule: string }>,
    ) => {
      const tmpRules = [...state.company.AIRules];
      const foundRule = tmpRules.find(
        (rule) => rule._id === action.payload.ruleId,
      );
      if (foundRule) {
        foundRule.rule = action.payload.rule;
      }
      state.company.AIRules = tmpRules;
    },
    deleteAIRule: (state, action: PayloadAction<string>) => {
      const tmpRules = [...state.company.AIRules];
      const filteredRules = tmpRules.filter(
        (rule) => rule._id !== action.payload,
      );
      state.company.AIRules = filteredRules;
    },
    setSocketStatus: (state, action: PayloadAction<boolean>) => {
      state.isSocketOn = action.payload;
    },
    login: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    logout: (state) => {
      state.token = undefined;
    },
  },
});

export const {
  setCompanyData,
  setCompanyPhoto,
  setUserPhoto,
  setCompanyName,
  setUserName,
  setCompanyWelcomeMessage,
  setCompanyChatConfig,
  setTelegramStatus,
  setAutoAssignBiensStatus,
  setAIRules,
  updateAIRule,
  deleteAIRule,
  setSocketStatus,
  login,
  logout,
} = authSlice.actions;

export default authSlice.reducer;
