import { combineReducers, configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./slices/authSlice";
import ConversationsReducer from "./slices/conversationsSlice";
import CustomersReducer from "./slices/customersSlice";
import TeamReducer from "./slices/teamSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
  key: "root",
  blacklist: ["conversations", "customers"],
  storage,
};

const combinedReducers = combineReducers({
  auth: AuthReducer,
  conversations: ConversationsReducer,
  customers: CustomersReducer,
  team: TeamReducer,
});

const persistedReducer = persistReducer(persistConfig, combinedReducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
