import React, { useMemo, useRef, useState } from "react";
import { HexColorPicker } from "react-colorful";
import useOutsideClick from "../../hooks/useOutsideClick";

type Props = {
  color: string;
  setColor: (newVal: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};
const ColorPicker: React.FC<Props> = ({ color, setColor, onFocus, onBlur }) => {
  const colorPickerRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useOutsideClick(colorPickerRef, () => setIsMenuOpen(false));

  useMemo(() => {
    if (isMenuOpen) {
      if (onFocus) {
        onFocus();
      }
    } else {
      if (onBlur) {
        onBlur();
      }
    }
  }, [isMenuOpen]);

  return (
    <div
      className="border-2 rounded p-1 flex items-center relative"
      onClick={() => setIsMenuOpen(true)}
      ref={colorPickerRef}
    >
      <div
        className={`w-5 h-5 rounded me-2 border-2`}
        style={{ background: color }}
      />
      <input
        type="text"
        className="text-sm text-gray-700 outline-0"
        value={color}
        onChange={(e) => setColor(e.target.value)}
        maxLength={7}
      />
      {isMenuOpen ? (
        <HexColorPicker
          color={color}
          onChange={setColor}
          onFocus={() => (onFocus ? onFocus() : null)}
          onBlur={() => (onBlur ? onBlur() : null)}
        />
      ) : null}
    </div>
  );
};

export default ColorPicker;
