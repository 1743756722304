import React, { memo } from "react";
import {
  Facebook,
  Instagram,
  Language,
  Telegram,
  WhatsApp,
} from "@mui/icons-material";
import { Customer } from "../../store/slices/customersSlice";
import { useTranslation } from "react-i18next";

type Props = {
  source: Customer["source"];
  textClassName?: string;
};
const CustomerSource: React.FC<Props> = ({ source, textClassName }) => {
  const { t } = useTranslation();
  const pClassName =
    "text-xs text-ellipsis overflow-hidden text-gray-500 whitespace-nowrap max-w-[20vw] ms-1";

  return (
    <>
      {source === "web" ? (
        <Language sx={{ fontSize: 18, color: "#00a2e8" }} />
      ) : source === "whatsapp" ? (
        <WhatsApp sx={{ fontSize: 18, color: "#25D366" }} />
      ) : source === "messenger" ? (
        <Facebook sx={{ fontSize: 18, color: "#1877F2" }} />
      ) : source === "instagram" ? (
        <Instagram sx={{ fontSize: 18, color: "#DD2A7B" }} />
      ) : source === "telegram" ? (
        <Telegram sx={{ fontSize: 18, color: "#24A1DE" }} />
      ) : null}
      <p className={`${pClassName} ${textClassName ? textClassName : ""}`}>
        {t(source)}
      </p>
    </>
  );
};

export default memo(CustomerSource);
