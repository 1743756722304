import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useMemo, useState } from "react";
import moment from "moment";
import DropdownSelect from "../../components/DropdownSelect";

const Usage = () => {
  const {
    company: { createdAt },
  } = useSelector((state: RootState) => state.auth);
  const [pastMonths, setPastMonths] = useState<
    { label: string; value: string }[]
  >([]);
  const [selectedMonth, setSelectedMonth] = useState<string>("");

  useMemo(() => {
    const getMonths = (start: moment.Moment, end: moment.Moment) =>
      Array.from({ length: end.diff(start, "month") + 2 }).map((_, index) => ({
        label: moment(start).add(index, "month").format("MMM/YYYY"),
        value: moment(start).add(index, "month").format("MMM/YYYY"),
      }));

    const months = getMonths(moment(createdAt), moment());
    setPastMonths(months.reverse());
    setSelectedMonth(months[0].value);
  }, [createdAt]);

  return (
    <div>
      <div className="bg-white p-2 border-2 rounded-lg mb-2">
        <div className="flex items-start justify-between">
          <div>
            <h3 className="font-bold text-sm text-gray-700">
              March 2024 Usage
            </h3>
            <span className="text-gray-500 text-xs">
              Only conversation handled by the AI chatbot are included in the
              count.
            </span>
          </div>
          <DropdownSelect
            label={"Month"}
            options={pastMonths}
            selectedValue={selectedMonth}
            handleChangeValue={setSelectedMonth}
          />
        </div>
        <div className="mt-2">
          <div className="flex items-center justify-between">
            <span className="text-gray-500 text-xs mb-1">
              1 March 2024 - Today
            </span>
            <span className="text-gray-500 text-xs mb-1">Quota: 320/10000</span>
          </div>
          <div className="w-full bg-sky-200 h-4 rounded-full">
            <div className="h-4 w-[50%] bg-biens rounded-full"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Usage;
