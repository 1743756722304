import { Link } from "react-router-dom";
import Logo from "../../assets/images/biens-logo.png";
import LoginInput from "../../components/LoginInput";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";
import { useState } from "react";
const ForgotPassword = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  return (
    <form
      className="bg-white rounded-lg shadow-2xl w-screen h-screen lg:w-[20vw] lg:min-w-[350px] lg:h-auto"
      action=""
    >
      <div className="flex p-5">
        <img src={Logo} alt="biens-logo" className="w-[50px] h-[50px]" />
        <div className="flex flex-col ms-2 justify-between">
          <h1 className="font-bold">{t("reset_password_header")}</h1>
          <span className="text-xs">
            {t("back_page")}
            <Link
              to={"/sign-in"}
              className="text-biens transition hover:transition hover:text-sky-700 ms-1"
            >
              {t("login_page")}
            </Link>
          </span>
        </div>
      </div>
      <hr />
      <div className="p-5">
        <LoginInput
          id={"email"}
          type={"email"}
          labelText={t("email_input")}
          descriptionText={t("we_will_send_reset_link")}
          placeholder={t("email_input_placeholder")}
          isRequired
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button title={t("send_button")} type="submit" onClick={() => null} />
      </div>
    </form>
  );
};

export default ForgotPassword;
