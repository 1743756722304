import React, { useState } from "react";
import ColorPicker from "../../components/ColorPicker";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import {
  ExpandMore,
  Splitscreen,
  Input,
  Forum,
  ControlCamera,
  QuestionMark,
} from "@mui/icons-material";
import Button from "../../components/Button";
import MockChat from "./MockChat";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Company, setCompanyChatConfig } from "../../store/slices/authSlice";
import Tooltip from "@mui/material/Tooltip";
import { toast } from "react-toastify";
import MockIcon from "./MockIcon";
import ResetChatConfigDialog from "../../components/Dialog/ResetChatConfig";
import { DEFAULT_CHAT_CONFIG } from "../../store/defaults";
import { REQUEST_PUT_CHAT_CONFIG } from "../../api/requests";

type AccordionItem = {
  title: string;
  tooltip?: string;
  type: string;
  value: keyof Company["chatConfig"];
  onFocus?: () => void;
  onBlur?: () => void;
  maxLength?: number;
};
type ChatConfigItem = {
  key: "layout" | "input" | "messages";
  accordionName: string;
  accordionId: string;
  icon: JSX.Element;
  items: AccordionItem[];
};
type ChatConfigs = ChatConfigItem[];
const ChatConfig = () => {
  const dispatch = useDispatch();
  const { company } = useSelector((state: RootState) => state.auth);
  const [chatConfig, setChatConfig] = useState(company.chatConfig);
  const [expanded, setExpanded] = useState<
    "layout" | "input" | "messages" | "position"
  >();
  const [onInputFocus, setOnInputFocus] = useState(false);
  const [onDisabledInputFocus, setOnDisabledInputFocus] = useState(false);
  const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);
  const [isConfigUpdating, setIsConfigUpdating] = useState(false);

  const CHAT_CONFIG: ChatConfigs = [
    {
      key: "layout",
      accordionName: "Layout",
      accordionId: "layout-accordion",
      icon: <Splitscreen className="text-gray-600 me-1" />,
      items: [
        {
          title: "Header background color",
          type: "colorpicker",
          value: "hBg",
        },
        {
          title: "Chat background color",
          type: "colorpicker",
          value: "cBg",
        },
        {
          title: "Assistant name text color",
          tooltip: "The text color of company name.",
          type: "colorpicker",
          value: "asTc",
        },
        {
          title: "Online status text color",
          tooltip: "The text color of 'online'.",
          type: "colorpicker",
          value: "osTc",
        },
      ],
    },
    {
      key: "input",
      accordionName: "Input",
      accordionId: "input-accordion",
      icon: <Input className="text-gray-600 me-1" />,
      items: [
        {
          title: "Input background color",
          type: "colorpicker",
          value: "iBg",
        },
        {
          title: "Input text color",
          tooltip: "The text color for filled input texts.",
          type: "colorpicker",
          value: "iTc",
          onFocus: () => setOnInputFocus(true),
          onBlur: () => setOnInputFocus(false),
        },
        {
          title: "Input placeholder",
          tooltip: "The text when input is empty",
          type: "input",
          value: "iT",
          maxLength: 40,
        },
        {
          title: "Disabled input background color",
          tooltip:
            "This input variant just works whenever user sends message and ai works",
          type: "colorpicker",
          value: "diBg",
          onFocus: () => setOnDisabledInputFocus(true),
          onBlur: () => setOnDisabledInputFocus(false),
        },
        {
          title: "Disabled sent icon color",
          tooltip: "The icon color when input is empty",
          type: "colorpicker",
          value: "dSIc",
        },
        {
          title: "Active sent icon color",
          tooltip: "The icon color when input is filled",
          type: "colorpicker",
          value: "aSIc",
          onFocus: () => setOnInputFocus(true),
          onBlur: () => setOnInputFocus(false),
        },
      ],
    },
    {
      key: "messages",
      accordionName: "Messages",
      accordionId: "messages-accordion",
      icon: <Forum className="text-gray-600 me-1" />,
      items: [
        {
          title: "Received message background color",
          type: "colorpicker",
          value: "rmBg",
        },
        {
          title: "Received message text color",
          type: "colorpicker",
          value: "rmTc",
        },
        {
          title: "Received message date color",
          type: "colorpicker",
          value: "rmDc",
        },
        {
          title: "Sent message background color",
          type: "colorpicker",
          value: "smBg",
        },
        {
          title: "Sent message text color",
          type: "colorpicker",
          value: "smTc",
        },
        {
          title: "Sent message date color",
          type: "colorpicker",
          value: "smDc",
        },
      ],
    },
  ];

  const changeConfigVal = (key: keyof Company["chatConfig"], val: string) => {
    const tmpChatConfig = { ...chatConfig };
    // @ts-ignore
    tmpChatConfig[key] = val;
    setChatConfig(tmpChatConfig);
  };

  const handleUpdateConfig = () => {
    setIsConfigUpdating(true);
    REQUEST_PUT_CHAT_CONFIG(chatConfig)
      .then(() => {
        dispatch(setCompanyChatConfig(chatConfig));
        toast.success("Your chat config successfully updated");
      })
      .catch(() => toast.error("Error happened when updating chat config"))
      .finally(() => setIsConfigUpdating(false));
  };

  return (
    <div className="container mx-auto h-full lg:px-3 lg:py-4">
      {isResetDialogOpen ? (
        <ResetChatConfigDialog
          setChatConfig={setChatConfig}
          closeDialog={() => setIsResetDialogOpen(false)}
        />
      ) : null}
      <div className="flex w-full h-dvh lg:h-full">
        <div className="hidden lg:flex w-[30%] h-full shadow rounded-tl-lg rounded-bl-lg bg-white flex-col">
          <div className="p-2 mb-2 h-full overflow-y-auto">
            {CHAT_CONFIG.map((config) => (
              <Accordion
                key={config.key}
                elevation={0}
                disableGutters={true}
                sx={{
                  "&:before": {
                    display: "none",
                  },
                  "& > div": {
                    padding: 0,
                  },
                }}
                expanded={expanded === config.key}
                onChange={(e, expanded) =>
                  setExpanded(expanded ? config.key : undefined)
                }
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls={config.accordionId}
                  id={config.accordionId}
                >
                  <div className="flex items-center">
                    {config.icon}
                    {config.accordionName}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {config.items.map((item, i) => (
                    <>
                      <div className="flex items-center">
                        <h3 className="font-bold text-sm text-gray-700 my-2">
                          {item.title}
                        </h3>
                        {item.tooltip ? (
                          <Tooltip title={item.tooltip}>
                            <QuestionMark
                              sx={{ fontSize: 14 }}
                              className="text-biens ms-2"
                            />
                          </Tooltip>
                        ) : null}
                      </div>

                      {item.type === "colorpicker" ? (
                        <ColorPicker
                          color={chatConfig[item.value] as string}
                          setColor={(newVal) =>
                            changeConfigVal(item.value, newVal)
                          }
                          onFocus={item.onFocus || undefined}
                          onBlur={item.onBlur || undefined}
                        />
                      ) : item.type === "input" ? (
                        <input
                          type="text"
                          className="border-2 rounded p-1 flex items-center relative w-full text-sm outline-0"
                          value={chatConfig[item.value]}
                          onChange={(e) =>
                            changeConfigVal(item.value, e.target.value)
                          }
                          maxLength={item.maxLength}
                        />
                      ) : null}
                    </>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}

            <Accordion
              elevation={0}
              disableGutters={true}
              expanded={expanded === "position"}
              onChange={(e, expanded) =>
                setExpanded(expanded ? "position" : undefined)
              }
              sx={{
                "&:before": {
                  display: "none",
                },
                "& > div": {
                  padding: 0,
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="position-accordion"
                id="position-accordion"
              >
                <div className="flex items-center">
                  <ControlCamera className="text-gray-600 me-1" />
                  Position
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <h3 className="font-bold text-sm text-gray-700 my-2">
                  Display at window's
                </h3>
                <div className="flex items-center justify-between">
                  <Button
                    title="Bottom left"
                    type="button"
                    size="sm"
                    variant={chatConfig.dP === "l" ? "filled" : "outlined"}
                    className="w-full"
                    onClick={() => changeConfigVal("dP", "l")}
                  />
                  <Button
                    title="Bottom right"
                    type="button"
                    size="sm"
                    variant={chatConfig.dP === "r" ? "filled" : "outlined"}
                    className="w-full ms-2"
                    onClick={() => changeConfigVal("dP", "r")}
                  />
                </div>
                <div className="rounded flex items-center justify-between my-2">
                  <h3 className="font-bold text-sm text-gray-700">
                    Bottom margin
                  </h3>
                  <div>
                    <input
                      type="number"
                      className="max-w-[30px] text-center outline-0 border-2 rounded me-1"
                      value={chatConfig.vM || DEFAULT_CHAT_CONFIG.vM}
                      onChange={(e) => changeConfigVal("vM", e.target.value)}
                      max={99}
                      min={0}
                    />
                    <span>px</span>
                  </div>
                </div>
                <div className="rounded flex items-center justify-between my-2">
                  <h3 className="font-bold text-sm text-gray-700">
                    Horizontal margin
                  </h3>
                  <div>
                    <input
                      type="number"
                      className="max-w-[30px] text-center outline-0 border-2 rounded me-1"
                      value={chatConfig.hM || DEFAULT_CHAT_CONFIG.hM}
                      onChange={(e) => changeConfigVal("hM", e.target.value)}
                      max={99}
                      min={0}
                    />
                    <span>px</span>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="flex items-center p-2 justify-between border-t-2">
            <Button
              title="Reset"
              type="button"
              size="sm"
              variant="outlined"
              onClick={() => setIsResetDialogOpen(true)}
            />
            <Button
              title="Save"
              type="button"
              size="sm"
              onClick={handleUpdateConfig}
              isLoading={isConfigUpdating}
            />
          </div>
        </div>
        <div className="bg-gray-100 w-screen lg:w-[70%] border-l-2 shadow rounded-tr-lg rounded-br-lg p-2 flex items-center justify-center">
          {expanded === "position" ? (
            <MockIcon
              vM={chatConfig.vM || DEFAULT_CHAT_CONFIG.vM}
              hM={chatConfig.hM || DEFAULT_CHAT_CONFIG.hM}
              dP={chatConfig.dP || DEFAULT_CHAT_CONFIG.dP}
            />
          ) : (
            <MockChat
              chatConfig={chatConfig}
              isInputFocused={onInputFocus}
              isInputDisabled={onDisabledInputFocus}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatConfig;
