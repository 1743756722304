import React from "react";
type Props = {
  checked: boolean;
  onChangeToggle: (newVal: boolean) => void;
};
const ToggleInput: React.FC<Props> = ({ checked, onChangeToggle }) => {
  return (
    <label className="cursor-pointer">
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => onChangeToggle(e.target.checked)}
        className="sr-only peer"
      />
      <div className="relative w-11 h-6 bg-gray-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-biens" />
    </label>
  );
};

export default ToggleInput;
