import React, { useRef, useState } from "react";
import moment from "moment";
import { Conversation } from "../../store/slices/conversationsSlice";
import { ChevronRight, ChevronLeft } from "@mui/icons-material";
import Button from "../Button";

type Props = {
  customer: Conversation;
};
const CustomerInfo: React.FC<Props> = ({ customer }) => {
  const customerInfoDiv = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(true);

  const handleOpenClose = () => {
    if (customerInfoDiv.current) {
      const isOpen = customerInfoDiv.current.classList.contains(
        "animate-customer-profile-open",
      );
      if (isOpen) {
        customerInfoDiv.current.classList.remove(
          "animate-customer-profile-open",
        );
        customerInfoDiv.current.classList.add("animate-customer-profile-close");
        setIsOpen(false);
      } else {
        customerInfoDiv.current.classList.remove(
          "animate-customer-profile-close",
        );
        customerInfoDiv.current.classList.add("animate-customer-profile-open");
        setIsOpen(true);
      }
    }
  };

  return (
    <div
      ref={customerInfoDiv}
      className={`hidden lg:block h-screen overflow-y-auto bg-white w-0 animate-customer-profile-open border-l-2`}
    >
      <div
        className={`border-b-2 flex items-center h-[60px] ${isOpen ? "py-2 ps-5 pe-2 justify-between" : "justify-center"}`}
      >
        {isOpen ? <h6 className="text-gray-700">Customer Profile</h6> : null}
        <Button
          type="button"
          variant="outlined"
          size="sm"
          className="items-center justify-center min-h-[36px] max-h-[36px] min-w-[36px] max-w-[36px] rounded-lg"
          title={<>{isOpen ? <ChevronRight /> : <ChevronLeft />}</>}
          onClick={handleOpenClose}
        />
      </div>
      {isOpen ? (
        <div className="px-5 py-2 text-sm">
          <table className="table-fixed">
            <tbody>
              <tr>
                <td className="text-gray-500 py-1">First View</td>
                <td className="ps-2">{moment(customer.createdAt).fromNow()}</td>
              </tr>
              <tr>
                <td className="text-gray-500 py-1">Last View</td>
                <td className="ps-2">{moment(customer.lastView).fromNow()}</td>
              </tr>
              <tr>
                <td className="text-gray-500 py-1">Total Visits</td>
                <td className="ps-2">{customer.visitCount}</td>
              </tr>
              <tr>
                <td className="text-gray-500 py-1">Language</td>
                <td className="ps-2">{customer.language}</td>
              </tr>
              <tr>
                <td className="text-gray-500 py-1">Country</td>
                <td className="ps-2">{customer.country_code}</td>
              </tr>
              <tr>
                <td className="text-gray-500 py-1">City / State</td>
                <td className="ps-2">
                  {customer.city}/{customer.state}
                </td>
              </tr>
              <tr>
                <td className="text-gray-500 py-1">Timezone</td>
                <td className="ps-2">{customer.timezone}</td>
              </tr>
              <tr>
                <td className="text-gray-500 py-1">Platform</td>
                <td className="ps-2">{customer.deviceType}</td>
              </tr>
              {customer.browserName ? (
                <tr>
                  <td className="text-gray-500 py-1">Browser</td>
                  <td className="ps-2">{customer.browserName}</td>
                </tr>
              ) : null}
              {customer.email ? (
                <tr>
                  <td className="text-gray-500 py-1">Email</td>
                  <td className="ps-2">{customer.email}</td>
                </tr>
              ) : null}
              {customer.phoneNumber ? (
                <tr>
                  <td className="text-gray-500 py-1">Phone</td>
                  <td className="ps-2">{customer.phoneNumber}</td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  );
};

export default CustomerInfo;
