import React, { memo } from "react";
import { ClickAwayListener } from "@mui/material";
import MainCard from "./MainCard";
import { Search, FlashOnOutlined } from "@mui/icons-material";

type Props = {
  handleCloseMenu: () => void;
};

const DUMMY_FAST_REPLIES = [
  {
    id: "1",
    name: "Greet!",
    description: "Hello, how can i help you?",
  },
  {
    id: "2",
    name: "Thanks!",
    description: "Thank you, so much!",
  },
  {
    id: "3",
    name: "See you!",
    description: "Wish you best! See you soon!",
  },
];

const DiscountsMenu: React.FC<Props> = ({ handleCloseMenu }) => {
  return (
    <div className="absolute top-[-510px] left-0">
      <ClickAwayListener onClickAway={handleCloseMenu}>
        <div className="px-5">
          <MainCard elevation={8} content={false}>
            <div className="flex flex-col justify-between max-w-[400px] min-w-[300px] h-[500px] bg-white">
              <form action="">
                <div className="p-2 border-b-2 h-[55px]">
                  <div className="border-2 rounded-lg flex items-center py-1 px-2">
                    <Search className="text-gray-700" />
                    <input
                      type="text"
                      className="block w-full text-sm text-gray-700 outline-0 border-0 focus:outline-0 focus:border-0 mx-2 h-[24px]"
                      placeholder="Type a fast reply name or message"
                      required
                      autoFocus
                    />
                  </div>
                </div>
              </form>
              <div className="p-2 h-full overflow-y-auto">
                {DUMMY_FAST_REPLIES.map((reply) => (
                  <div
                    key={reply.id}
                    onClick={handleCloseMenu}
                    className="relative border-2 rounded p-2 flex items-center transition hover:transition hover:bg-gray-50 cursor-pointer mb-2"
                  >
                    <FlashOnOutlined className="text-gray-700 me-2" />
                    <div>
                      <h6 className="text-xs text-gray-700 font-bold pe-4">
                        {reply.name}
                      </h6>
                      <div className="text-xs text-gray-700">
                        {reply.description}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </MainCard>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default memo(DiscountsMenu);
