import { FormEvent } from "react";
import { Tooltip } from "@mui/material";

import {
  SentimentSatisfiedAltOutlined,
  ShoppingBagOutlined,
  LocalOfferOutlined,
  SendOutlined,
  FlashOnOutlined,
} from "@mui/icons-material";
import Button from "../Button";
import { CHAT_MENU_TYPES } from "./types";

const platform = window.navigator.userAgentData?.platform;
let helpText =
  platform && platform.toLowerCase().includes("mac")
    ? "cmd + enter"
    : "ctrl + enter";

export default function ChatMessageSend({
  handleSendMessage,
  message,
  setMessage,
  openMenu,
}: {
  handleSendMessage: (message: string) => void;
  message: string;
  setMessage: (newMessage: string) => void;
  openMenu: (menu: CHAT_MENU_TYPES) => void;
}) {
  const sendMessage = () => {
    handleSendMessage(message);
    setMessage("");
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendMessage();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="relative w-full border border-gray-200 rounded-lg bg-gray-50">
        <div className="px-4 py-2 bg-white rounded-t-lg">
          <label htmlFor="comment" className="sr-only">
            Your comment
          </label>
          <textarea
            id="comment"
            rows={4}
            className="w-full px-0 text-sm text-gray-900 bg-white border-0 focus:outline-0 min-h-14 max-h-60"
            placeholder="Write a message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            autoFocus
            required
            onKeyDown={(e) => {
              if ((e.ctrlKey || e.metaKey) && e.key === "Enter") {
                sendMessage();
              }
            }}
          ></textarea>
        </div>
        <div className="flex items-center justify-between px-3 py-2 border-t">
          <div className="flex ps-0 space-x-1">
            <button
              data-tooltip-target="tooltip-default"
              type="button"
              className="hidden md:inline-flex justify-center items-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100"
              onClick={() => openMenu("emoji")}
            >
              <SentimentSatisfiedAltOutlined className="text-biens" />
            </button>
            <Tooltip title={"Hızlı Yanıtlar"}>
              <button
                data-tooltip-target="tooltip-default"
                type="button"
                className="inline-flex justify-center items-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100"
                onClick={() => openMenu("fastReplies")}
              >
                <FlashOnOutlined className="text-biens" />
              </button>
            </Tooltip>
            <Tooltip title={"Ürün Öner"}>
              <button
                data-tooltip-target="tooltip-default"
                type="button"
                className="inline-flex justify-center items-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100"
                onClick={() => openMenu("products")}
              >
                <ShoppingBagOutlined className="text-biens" />
              </button>
            </Tooltip>
            <Tooltip title={"İndirim Kuponları"}>
              <button
                data-tooltip-target="tooltip-default"
                type="button"
                className="inline-flex justify-center items-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100"
                onClick={() => openMenu("discounts")}
              >
                <LocalOfferOutlined className="text-biens" />
              </button>
            </Tooltip>
          </div>

          <Button
            title={
              <>
                Send
                <SendOutlined className="ms-2" />
              </>
            }
            size="sm"
            type="submit"
            className={`${!message.length ? "opacity-75" : "opacity-100"}`}
          />
        </div>
      </div>
      <div className="hidden md:flex w-full justify-end text-xs text-gray-700 mt-1">
        You may press <b className="mx-1">{helpText}</b> keys for sending
        message.
      </div>
    </form>
  );
}
