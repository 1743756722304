import {
  Instagram,
  Language,
  Facebook,
  WhatsApp,
  Telegram,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Company } from "../../store/slices/authSlice";
import IntegrationBox from "../../components/IntegrationBox";

export type Integration = {
  name: string;
  key: keyof Company;
  link: string;
  icon: JSX.Element;
  description: string;
};

const IntegrationOptions: Integration[] = [
  {
    name: "Web",
    key: "isWeb",
    link: "/dashboard/integrations/web",
    icon: (
      <Language
        sx={{
          fontSize: 48,
          color: "#00a2e8",
        }}
      />
    ),
    description:
      "Integrate chatbot into your website and response customers with biens.ai",
  },
  {
    name: "Whatsapp",
    key: "isWhatsapp",
    link: "/dashboard/integrations/whatsapp",
    icon: (
      <WhatsApp
        sx={{
          fontSize: 48,
          color: "#25D366",
        }}
      />
    ),
    description: "Handle incoming whatsapp messages with biens.ai",
  },
  {
    name: "Instagram",
    key: "isInstagram",
    link: "/dashboard/integrations/instagram",
    icon: (
      <Instagram
        sx={{
          fontSize: 48,
          color: "#DD2A7B",
        }}
      />
    ),
    description: "Handle incoming instagram messages with biens.ai",
  },
  {
    name: "Messenger",
    key: "isMessenger",
    link: "/dashboard/integrations/messenger",
    icon: (
      <Facebook
        sx={{
          fontSize: 48,
          color: "#1877F2",
        }}
      />
    ),
    description: "Handle incoming facebook messages with biens.ai",
  },
  {
    name: "Telegram",
    key: "isTelegram",
    link: "/dashboard/integrations/telegram",
    icon: (
      <Telegram
        sx={{
          fontSize: 48,
          color: "#24A1DE",
        }}
      />
    ),
    description: "Handle incoming telegram messages with biens.ai",
  },
];

const Integrations = () => {
  const { company } = useSelector((state: RootState) => state.auth);
  return (
    <div className="lg:container lg:mx-auto px-3 py-4 pt-[calc(50px+1rem)] lg:pt-4">
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
        {IntegrationOptions.map((integration, i) => (
          <IntegrationBox
            key={i}
            integration={integration}
            isCompleted={!!company[integration.key]}
          />
        ))}
      </div>
    </div>
  );
};

export default Integrations;
