import React, { memo } from "react";
import { CheckCircle } from "@mui/icons-material";
import { Link } from "react-router-dom";

type Integration = {
  name: string;
  link: string;
  icon: JSX.Element;
  description: string;
};

type Props = {
  integration: Integration;
  isCompleted: boolean;
};

const IntegrationBox: React.FC<Props> = ({ integration, isCompleted }) => (
  <Link
    to={integration.link}
    className="flex flex-col justify-center bg-white py-10 px-2 rounded-lg shadow cursor-pointer transition hover:transition hover:bg-gray-200 relative"
  >
    {isCompleted ? (
      <div className="absolute top-[-12px] right-[-12px]">
        <CheckCircle
          sx={{
            fontSize: 36,
            color: "#25D366",
          }}
        />
      </div>
    ) : null}
    {integration.icon}
    <div className="ps-2">
      <h3 className="font-bold my-2">{integration.name}</h3>
      <span className="text-xs text-gray-700">{integration.description}</span>
    </div>
  </Link>
);

export default memo(IntegrationBox);
