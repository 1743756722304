import { Link } from "react-router-dom";
import LoginInput from "../../components/LoginInput";
import Button from "../../components/Button";
import Logo from "../../assets/images/biens-logo.png";
import { useTranslation } from "react-i18next";
import { FormEventHandler, useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../../store/slices/authSlice";
import { REQUEST_LOGIN } from "../../api/requests";
import { toast } from "react-toastify";
const Login = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleLogin: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (password.length > 7) {
      REQUEST_LOGIN(email, password)
        .then(({ data }) => {
          dispatch(login(data.data.accessToken));
        })
        .catch(() => {
          toast.error("Login error");
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      toast.error("Password must be longer than 8 chars");
      setIsLoading(false);
    }
  };
  return (
    <form
      className="bg-white rounded-lg shadow-2xl w-screen h-screen lg:w-[20vw] lg:min-w-[350px] lg:h-auto"
      onSubmit={handleLogin}
    >
      <div className="flex p-5">
        <img src={Logo} alt="biens-logo" className="w-[50px] h-[50px]" />
        <div className="flex flex-col ms-2 justify-between">
          <h1 className="font-bold">{t("login_header")}</h1>
          <span className="text-xs">
            {t("dont_have_an_account")}
            <Link
              to={"/sign-up"}
              className="text-biens transition hover:transition hover:text-sky-700 ms-1"
            >
              {t("register")}
            </Link>
          </span>
        </div>
      </div>
      <hr />
      <div className="p-5">
        <LoginInput
          id={"email"}
          type={"email"}
          labelText={t("email_input")}
          placeholder={t("email_input_placeholder")}
          isRequired
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <LoginInput
          id={"password"}
          type={"password"}
          labelText={t("password_input")}
          placeholder={t("password_input_placeholder")}
          isRequired
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button title={t("login_header")} type="submit" isLoading={isLoading} />
      </div>
      <hr />
      <div className="p-5">
        <span className="text-xs">
          {t("did_you_forgot_password")}
          <Link
            to={"/forgot-password"}
            className="text-biens transition hover:transition hover:text-sky-700 ms-1"
          >
            {t("reset_password")}
          </Link>
        </span>
      </div>
    </form>
  );
};

export default Login;
