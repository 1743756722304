import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import XIcon from "../../assets/icons/XIcon";
import moment from "moment";
import PaperPlane from "../../assets/icons/PaperPlane";
import { Link } from "react-router-dom";
import "./MockChat.css";
import { Company } from "../../store/slices/authSlice";
import { DEFAULT_CHAT_CONFIG } from "../../store/defaults";

type Props = {
  chatConfig: Company["chatConfig"];
  isInputFocused: boolean;
  isInputDisabled: boolean;
};
const MockChat: React.FC<Props> = ({
  chatConfig,
  isInputFocused,
  isInputDisabled,
}) => {
  const { company } = useSelector((state: RootState) => state.auth);
  const [input, setInput] = useState("");

  useMemo(() => {
    setInput(isInputFocused ? "Some user message..." : "");
  }, [isInputFocused]);

  return (
    <div className="flex flex-col h-[80vh] w-[20vw] min-w-[350px] shadow-lg rounded-2xl">
      <div
        className="biens-chat-header"
        style={{ background: chatConfig.hBg || DEFAULT_CHAT_CONFIG.hBg }}
      >
        <div className="biens-chat-img">
          <img src={company.companyPhotoUrl} alt="chat-logo" />
          <div className="green-dot" />
        </div>
        <div className="biens-chat-assistant">
          <h3 style={{ color: chatConfig.asTc || DEFAULT_CHAT_CONFIG.asTc }}>
            {company.companyName}
          </h3>
          <span style={{ color: chatConfig.osTc || DEFAULT_CHAT_CONFIG.asTc }}>
            Online
          </span>
        </div>
        <XIcon
          color={"#757575"}
          height={18}
          className="biens-x-icon"
          onClick={() => {
            window.parent.postMessage(
              "close-chat",
              document.location.ancestorOrigins[0],
            );
          }}
        />
      </div>
      <div
        className="biens-chat-area"
        style={{ background: chatConfig.cBg || DEFAULT_CHAT_CONFIG.cBg }}
      >
        <div className={`biens-chat-message assistant`}>
          <div
            className={`biens-chat-bubble assistant`}
            style={{
              background: chatConfig.rmBg || DEFAULT_CHAT_CONFIG.rmBg,
            }}
          >
            <h6
              style={{
                color: chatConfig.rmTc || DEFAULT_CHAT_CONFIG.rmTc,
              }}
            >
              {company.welcomeMessage}
            </h6>
            <span
              style={{
                color: chatConfig.rmDc || DEFAULT_CHAT_CONFIG.rmDc,
              }}
            >
              {moment().format("HH:mm")}
            </span>
          </div>
        </div>
        <div className={`biens-chat-message user`}>
          <div
            className={`biens-chat-bubble user`}
            style={{
              background: chatConfig.smBg || DEFAULT_CHAT_CONFIG.smBg,
            }}
          >
            <h6
              style={{
                color: chatConfig.smTc || DEFAULT_CHAT_CONFIG.smTc,
              }}
            >
              Hi 👋🏼
            </h6>
            <span
              style={{
                color: chatConfig.smDc || DEFAULT_CHAT_CONFIG.smDc,
              }}
            >
              {moment().format("HH:mm")}
            </span>
          </div>
        </div>
      </div>
      <div
        className={`biens-input-area`}
        style={{
          background: isInputDisabled
            ? chatConfig.diBg || DEFAULT_CHAT_CONFIG.diBg
            : chatConfig.iBg || DEFAULT_CHAT_CONFIG.iBg,
        }}
      >
        <input
          type="text"
          value={input}
          placeholder={chatConfig.iT || DEFAULT_CHAT_CONFIG.iT}
          disabled={isInputDisabled}
          style={{
            color: chatConfig.iTc || DEFAULT_CHAT_CONFIG.iTc,
          }}
        />
        <button className="biens-send-button">
          <PaperPlane
            color={
              isInputDisabled || !input.length
                ? chatConfig.dSIc || DEFAULT_CHAT_CONFIG.dSIc
                : chatConfig.aSIc || DEFAULT_CHAT_CONFIG.aSIc
            }
            height={24}
          />
        </button>
      </div>
      <div
        className="biens-chat-footer"
        style={{ background: chatConfig.cBg || DEFAULT_CHAT_CONFIG.cBg }}
      >
        <span>Powered by</span>
        <Link className="biens-link" to="https://biens.ai" target="_blank">
          biens.ai
        </Link>
      </div>
    </div>
  );
};

export default MockChat;
